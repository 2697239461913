
.parent-container{
    display: flex;
    justify-content: space-between;
    width: 80vw;
    border: 2px solid black;
    height: 80vh;
    flex-wrap: wrap;
    position: relative; 
}

.map-container {
    justify-content: space-between;
    position: relative;
    /* Adjust this percentage based on the parent container's width */
    height: 80vh; /* Adjust this percentage based on the parent container's height */
    width: 100vw; /* Adjust this percentage based on the parent container's width */
    margin: 0; /* This is an approximation, adjust as needed */
    z-index: 0;

    /* background-color: blueviolet; Optional, uncomment if needed */
}

.sidebar {
    position: absolute;

    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    font-family: 'TTOctosquares-Regular';
    border-radius: 4px;
    height: 3vh; /* Adjust this value as needed */
    padding: 5px; /* Adjust for internal spacing */
    line-height: 1.0; /* Adjust line height as needed */
    text-align: left; /* Center text if needed */
}

.disclaimer {
    position: absolute;

    top: 0;
    left: 44%;
    z-index: 1;

    background-color: #23374be6;
    color: #fff;
    font-family: 'TTOctosquares-Regular';
    border-radius: 4px;
    height: 3vh; /* Adjust this value as needed */
    padding: 5px;
    line-height: 1.0; /* Adjust line height as needed */
    text-align: center; /* Center text if needed */
}


.mapboxgl-popup {
    height: 10px;
    color: black;
    font: 9px/0 'TTOctosquares-Regular', Arial, Helvetica;
}

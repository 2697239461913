:where(.css-dev-only-do-not-override-1qke4ga).ant-modal .ant-modal-footer {
    text-align: center;
}

input {
    border-radius: 0;
}

/* :where(.css-dev-only-do-not-override-1qke4ga).ant-modal .ant-modal-content {
    background-color: pink;
}

:where(.css-dev-only-do-not-override-1qke4ga).ant-modal .ant-modal-title {
    background-color: pink;
} */
/* .login{ */
    /* background: url("../../../public/images/backgrounds/mainbackground.png"); */
    /* background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
/* } */

.bgvideo{
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
}

:where(.css-dev-only-do-not-override-1qke4ga).ant-modal .ant-modal-footer {
    text-align: center;
}

:where(.css-dev-only-do-not-override-1qke4ga).ant-modal .ant-modal-content {
    background-color: pink;
}

input {
    background-color: rgba(31, 8, 8, 0)!important;
  }

input#basic_email {
    background-color: pink!important;
}
  

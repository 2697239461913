/* DataTableDemo.css */
/* .data-table{ 
    width: 100%;
    margin: "10px"
} */ 

/* customTableStyles.css */  
/* Target the column headers */
.ant-table-thead th {
    font-size: 16px; /* Set the desired font size for column headers */
  }
  
  /* Target the content cells */
  .ant-table-tbody td {
    font-size: 14px; /* Set the desired font size for content cells */
  }
  
/* The selected date cell and time cells in the Date Picker in Edit Flight Modal */
.ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner, .ant-picker-dropdown .ant-picker-cell-in-view:is(:where(.css-dev-only-do-not-override-40szbv).ant-picker-dropdown .ant-picker-cell-selected) .ant-picker-cell-inner {
  background-color: #047DE6;
}

/* Add the label for time picker using pseudo-element */
.ant-picker-time-panel .ant-picker-header .ant-picker-header-view::after {
  content: "HH\A0\A0\A0\A0\A0:\A0\A0\A0\A0\A0MM";
  display: block; /* Ensures the content appears on a new line */
  color: #ffffff; /* Sets the text color */
  /* font-size: 16px; Sets the text size */
  /* margin-top: -15px;  */
  margin: -35px 0 -15px 0;
}
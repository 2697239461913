*{
  margin: 0;
}
@font-face {
  font-family: "TTOctosquares-Bold";
  src: url("./fonts/TTOctosquares-Bold.ttf");
}
@font-face {
  font-family: "TTOctosquares-BoldIt";
  src: url("./fonts/TTOctosquares-BoldIt.ttf");
}
@font-face {
  font-family: "TTOctosquares-Thin";
  src: url("./fonts/TTOctosquares-Thin.ttf");
}
@font-face {
  font-family: "TTOctosquares-ThinIt";
  src: url("./fonts/TTOctosquares-ThinIt.ttf");
}
@font-face {
  font-family: "TTOctosquares-Regular";
  src: url("./fonts/TTOctosquares-Regular.ttf");
}
@font-face {
  font-family: "TTOctosquares-Italic";
  src: url("./fonts/TTOctosquares-Italic.ttf");
}

body{
  font-family: 'TTOctosquares-Regular';
  min-height: 100vh;
  /* background: url("../public/images/backgrounds/mainbackground.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  background-color: #000000;
  color: #ffffff;
  display: flex;

}
#root{
   flex-grow: 1;
   display: flex;
   justify-content: center;
   align-items: center;
}

.App{
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   align-items: center;
}

h1{
   font-size: 70px;
   letter-spacing: 1px;
   text-align: center;
}


/* 
   implement css modules global, compose etc
*/